import { NgModule } from '@angular/core';
import { OvGenericModule } from '@ov-suite/ui';
import { Domain } from '@ov-suite/models-admin';

@NgModule({
  imports: [
    OvGenericModule.forRoot({
      entity: Domain,
      api: 'adminlink',
      createTitle: 'Domain',
      route: ['domain'],
      tableTitle: 'Domain',
      permissionId: 901,
    }),
  ],
})
export class DomainModule {}
