import { NgModule } from '@angular/core';
import { OvGenericModule } from '@ov-suite/ui';
import { Factory } from '@ov-suite/models-admin';

@NgModule({
  imports: [
    OvGenericModule.forRoot({
      entity: Factory,
      api: 'adminlink',
      createTitle: 'Factory',
      route: ['factory'],
      tableTitle: 'Factory',
      tabs: [
        {
          path: '/factory',
          title: 'Factories & Warehouses',
        },
        {
          path: '/factory-area',
          title: 'Factory Area',
        },
        {
          path: '/inventory-location',
          title: 'Inventory Location',
        },
      ],
      permissionId: 904,
    }),
  ],
})
export class FactoryModule {}
