import { NgModule } from '@angular/core';
import { OvGenericModule } from '@ov-suite/ui';
import { Transporter } from '@ov-suite/models-admin';

@NgModule({
  imports: [
    OvGenericModule.forRoot({
      entity: Transporter,
      api: 'adminlink',
      createTitle: 'Transporter',
      route: ['transporter'],
      tableTitle: 'Transporter',
      permissionId: 908,
    }),
  ],
})
export class TransporterModule {}
