import { NgModule } from '@angular/core';
import { OvGenericModule } from '@ov-suite/ui';
import { Equipment } from '@ov-suite/models-admin';

@NgModule({
  imports: [
    OvGenericModule.forRoot({
      entity: Equipment,
      api: 'adminlink',
      createTitle: 'Equipment',
      route: ['equipment'],
      tableTitle: 'Equipment',
      tabs: [
        {
          path: '/equipment',
          title: 'Equipment',
        },
        {
          path: '/equipment-type',
          title: 'Equipment Type',
        },
      ],
      permissionId: 906,
    }),
  ],
})
export class EquipmentModule {}
