import { NgModule } from '@angular/core';
import { OvGenericModule } from '@ov-suite/ui';
import { ProductCategory } from '@ov-suite/models-admin';

@NgModule({
  imports: [
    OvGenericModule.forRoot({
      entity: ProductCategory,
      api: 'adminlink',
      createTitle: 'Product Category',
      route: ['product-category'],
      tableTitle: 'Product Categories',
      tabs: [
        { title: 'Product Categories', iconClass: '', path: '/product-category' },
        { title: 'Products', iconClass: '', path: '/product' },
        { title: 'Product SKUs', iconClass: '', path: '/product-sku' },
      ],
      permissionId: 905,
    }),
  ],
})
export class ProductCategoryModule {}
