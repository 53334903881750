import { NgModule } from '@angular/core';
import { OvGenericModule } from '@ov-suite/ui';
import { Product } from '@ov-suite/models-admin';

@NgModule({
  imports: [
    OvGenericModule.forRoot({
      entity: Product,
      api: 'adminlink',
      createTitle: 'Product',
      route: ['product'],
      tableTitle: 'Products',
      tabs: [
        { title: 'Product Categories', iconClass: '', path: '/product-category' },
        { title: 'Products', iconClass: '', path: '/product' },
        { title: 'Product SKUs', iconClass: '', path: '/product-sku' },
      ],
      permissionId: 905,
    }),
  ],
})
export class ProductModule {}
