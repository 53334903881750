import { NgModule } from '@angular/core';
import { OvGenericModule } from '@ov-suite/ui';
import { Tag } from '@ov-suite/models-admin';

@NgModule({
  imports: [
    OvGenericModule.forRoot({
      entity: Tag,
      api: 'adminlink',
      createTitle: 'Tag',
      route: ['tag'],
      tableTitle: 'Tag',
      permissionId: 907,
    }),
  ],
})
export class TagModule {}
