import { NgModule } from '@angular/core';
import { OvGenericModule } from '@ov-suite/ui';
import { Vehicle } from '@ov-suite/models-admin';

@NgModule({
  imports: [
    OvGenericModule.forRoot({
      entity: Vehicle,
      api: 'adminlink',
      createTitle: 'Vehicle',
      route: ['vehicle'],
      tableTitle: 'Vehicle',
      permissionId: 910,
    }),
  ],
})
export class VehicleModule {}
